const resources = {
    en: {
      translation: {
        "welcome": "Welcome to Bio Asparagus",
        "about": "About Us",
        "contact": "Contact Us",
        "description": "Fresh, organic asparagus grown with care."
      }
    },
    bg: {
      translation: {
        "welcome": "Добре дошли в Био Аспержи",
        "about": "За Нас",
        "contact": "Контакти",
        "description": "Свежи, органични аспержи, отглеждани с грижа."
      }
    }
  };
  
  export default resources;
  