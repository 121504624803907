import React, { useEffect } from "react";

const ContactForm = () => {
  useEffect(() => {
    // Dynamically load the validate.js script
    const script = document.createElement("script");
    script.src = "vendor/php-email-form/validate.js"; // Adjust path based on the location of validate.js
    script.async = true;
    document.body.appendChild(script);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);


  return (
    <form
      action="forms/contact.php"
      method="post"
      className="php-email-form"
      data-aos="fade-up"
      data-aos-delay="400"
      data-recaptcha-site-key="6LfjRngqAAAAABr9vNOVEggIZglIPBZzAsl9Esu7"
    >
      <div className="row gy-4">
        <div className="col-md-6">
          <input
            type="text"
            name="name"
            className="form-control"
            placeholder="Your Name"
            required
          />
        </div>

        <div className="col-md-6">
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Your Email"
            required
          />
        </div>

        <div className="col-md-12">
          <input
            type="text"
            className="form-control"
            name="subject"
            placeholder="Subject"
            required
          />
        </div>

        <div className="col-md-12">
          <textarea
            className="form-control"
            name="message"
            rows="6"
            placeholder="Message"
            required
          ></textarea>
        </div>

        <div className="col-md-12 text-center">
          <div className="loading">Изпраща се</div>
          <div className="error-message"></div>
          <div className="sent-message">Съобщението ви беше изпратено.</div>

          <button type="submit">Изпрати</button>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
