import React from "react";
import asparagus4 from "../assets/img/asparagus-4.webp";
import asparagus5 from "../assets/img/asparagus-5.webp";

const OurCommitmentSection = () => {
  return (
    <section id="features-details" className="features-details section">
      <div className="container">

        {/* First Feature Item */}
        <div className="row gy-4 justify-content-between features-item">
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <img src={asparagus4} className="img-fluid" alt="Asparagus Farm" />
          </div>
          <div className="col-lg-5 d-flex align-items-center" data-aos="fade-up" data-aos-delay="200">
            <div className="content">
              <h3>Нашият ангажимент към органичното земеделие</h3>
              <p>
                В "Био ферма за аспержи" се гордеем с нашия ангажимент към устойчивото земеделие. Нашите методи са насочени към запазване на почвеното здраве и екологичния баланс, за да ви предложим най-вкусните и хранителни аспержи.
              </p>
              <a href="#" className="btn more-btn">Научете повече</a>
            </div>
          </div>
        </div>

        {/* Second Feature Item */}
        <div className="row gy-4 justify-content-between features-item">
          <div className="col-lg-5 d-flex align-items-center order-2 order-lg-1" data-aos="fade-up" data-aos-delay="100">
            <div className="content">
              <h3>Защо да изберете нашите аспержи</h3>
              <p>
                Нашите аспержи са не само органични, но и богати на витамини и минерали, благодарение на методите на отглеждане, които съчетават традиции и иновации.
              </p>
              <ul>
                <li><i className="bi bi-easel flex-shrink-0"></i> Гарантирано качество и свежест.</li>
                <li><i className="bi bi-patch-check flex-shrink-0"></i> Сертифицирано био производство.</li>
                <li><i className="bi bi-brightness-high flex-shrink-0"></i> Поддържане на устойчивостта и екологичния баланс.</li>
              </ul>
              <a href="#" className="btn more-btn">Научете повече</a>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-up" data-aos-delay="200">
            <img src={asparagus5} className="img-fluid" alt="Organic Asparagus" />
          </div>
        </div>

      </div>
    </section>
  );
};

export default OurCommitmentSection;
