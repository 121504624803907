import { useEffect } from "react";

const usePreloader = () => {
  useEffect(() => {
    const preloader = document.querySelector("#preloader");
    if (preloader) {
      window.addEventListener("load", () => {
        preloader.remove();
      });
    }
  }, []);
};

export default usePreloader;
