import { useEffect } from "react";

const useToggleScrolled = () => {
  useEffect(() => {
    const handleScroll = () => {
      const body = document.body;
      const header = document.querySelector("#header");
      if (
        header &&
        (header.classList.contains("scroll-up-sticky") ||
          header.classList.contains("sticky-top") ||
          header.classList.contains("fixed-top"))
      ) {
        window.scrollY > 100
          ? body.classList.add("scrolled")
          : body.classList.remove("scrolled");
      }
    };

    document.addEventListener("scroll", handleScroll);
    handleScroll(); // Initialize on load

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
};

export default useToggleScrolled;
