import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="btn-group" role="group" aria-label="Language switcher">
      <button
        type="button"
        className={`btn btn-outline-primary d-flex align-items-center ${
          i18n.language === "en" ? "active" : ""
        }`}
        onClick={() => changeLanguage("en")}
      >
        <span className="me-2">
          <img
            src="https://flagcdn.com/w40/us.png"
            alt="English"
            className="rounded"
          />
        </span>
        English
      </button>
      <button
        type="button"
        className={`btn btn-outline-primary d-flex align-items-center ${
          i18n.language === "bg" ? "active" : ""
        }`}
        onClick={() => changeLanguage("bg")}
      >
        <span className="me-2">
          <img
            src="https://flagcdn.com/w40/bg.png"
            alt="Bulgarian"
            className="rounded"
          />
        </span>
        Български
      </button>
    </div>
  );
};

export default LanguageSwitcher;
