import React from "react";
import ContactForm from "./ContactForm";

const ContactSection = () => {
  return (
    <section id="contact" className="contact section">
      {/* Section Title */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Контакти</h2>
        <p>
          Свържете се с нас за повече информация относно нашите органични аспержи
          и устойчиви земеделски практики
        </p>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          {/* Address Section */}
          <div className="col-lg-6">
            <div
              className="info-item d-flex flex-column justify-content-center align-items-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <i className="bi bi-geo-alt"></i>
              <h3>Адрес</h3>
              <p>София, Младост-4, 1715</p>
            </div>
          </div>

          {/* Phone Section */}
          <div className="col-lg-3 col-md-6">
            <div
              className="info-item d-flex flex-column justify-content-center align-items-center"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <i className="bi bi-telephone"></i>
              <h3>Обадете се</h3>
              <p>+359 883 359 359</p>
            </div>
          </div>

          {/* Email Section */}
          <div className="col-lg-3 col-md-6">
            <div
              className="info-item d-flex flex-column justify-content-center align-items-center"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <i className="bi bi-envelope"></i>
              <h3>Пишете ни</h3>
              <p>info@bioasparagus.com</p>
            </div>
          </div>
        </div>

        <div className="row gy-4 mt-1">
          {/* Google Map */}
          <div
            className="col-lg-6"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7087.773566861167!2d23.37986748213528!3d42.62790370840791!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa86cf24a5f555%3A0x6e5e64693e999c4d!2sMladost%204Mladost%2C%201715%20Sofia%2C%20Bulgaria!5e1!3m2!1sen!2sus!4v1730498779665!5m2!1sen!2sus"
              frameBorder="0"
              style={{ border: 0, width: "100%", height: "400px" }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          {/* Contact Form */}
          <div
            className="col-lg-6"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <ContactForm />
          </div>
        </div>
        
      </div>
    </section>
  );
};

export default ContactSection;
