import React from "react";
import { useTranslation } from "react-i18next";
import HeroSection from "../components/HeroSection";
import AboutSection from "../components/AboutSection";
import OurCommitmentSection from "../components/OurCommitmentSection";
import ContactSection from "../components/ContactSection";

const Home = () => {
    const { t } = useTranslation();

  return (
    <>
    <HeroSection />
    <AboutSection />
    <OurCommitmentSection />
    <ContactSection />
    </>
  );
};

export default Home;
