import { useEffect } from "react";

const useNavMenuScrollspy = () => {
  useEffect(() => {
    const links = document.querySelectorAll(".navmenu a");

    const handleScrollspy = () => {
      links.forEach((link) => {
        if (!link.hash) return;
        const section = document.querySelector(link.hash);
        if (!section) return;

        const position = window.scrollY + 200;
        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          links.forEach((lnk) => lnk.classList.remove("active"));
          link.classList.add("active");
        } else {
          link.classList.remove("active");
        }
      });
    };

    document.addEventListener("scroll", handleScrollspy);
    handleScrollspy(); // Initialize on load

    return () => {
      document.removeEventListener("scroll", handleScrollspy);
    };
  }, []);
};

export default useNavMenuScrollspy;
