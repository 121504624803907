import React from "react";
import { NavLink } from "react-router-dom";
import menuItems from "../data/navigation"

const Navbar = () => {
  
  const renderSubItems = (subItems) => (
    <ul>
      {subItems.map((subItem, index) => (
        <li className={subItem.subItems ? "dropdown" : ""} key={index}>
          <NavLink to={subItem.to}>
            {subItem.label}
            {subItem.subItems && <i className="bi bi-chevron-right"></i>}
          </NavLink>
          {subItem.subItems && renderSubItems(subItem.subItems)}
        </li>
      ))}
    </ul>
  );

  return (
    <nav id="navmenu" className="navmenu">
      { <ul>
        {menuItems.map((item, index) => (
          <li className={item.dropdown ? "dropdown" : "" } key={index}>
             <NavLink
              to={item.to}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              {item.label}
              {item.dropdown && <i className="bi bi-chevron-down"></i>}
            </NavLink>
            {item.subItems && renderSubItems(item.subItems)}
          </li>
        ))}
      </ul> }
      <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
    </nav>
  );
};

export default Navbar;
