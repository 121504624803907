import { useEffect } from "react";

const ScrollTopButton = () => {
  useEffect(() => {
    const button = document.querySelector(".scroll-top");
    const handleScroll = () => {
      if (button) {
        window.scrollY > 100
          ? button.classList.add("active")
          : button.classList.remove("active");
      }
    };

    document.addEventListener("scroll", handleScroll);
    handleScroll(); // Initialize on load

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <button className="scroll-top" onClick={scrollToTop}>
      <i className="bi bi-arrow-up-short"></i>
    </button>
  );
};

export default ScrollTopButton;
