import React from "react";

const VarietyCard = ({ variety }) => {
  return (
    <div className="row gy-4">
      {/* Variety Info Section */}
      <div className="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
        <p className="variety">{variety.name}</p>
        <h3>About {variety.name}</h3>
        <p className="fst-italic">
          {variety.description || "Detailed information about this variety."}
        </p>
        <ul>
          {variety.features &&
            variety.features.map((feature, index) => (
              <li key={index}>
                <i className="bi bi-check-circle"></i> <span>{feature}</span>
              </li>
            ))}
        </ul>
      </div>

      {/* Variety Images Section */}
      <div className="col-lg-6 about-images" data-aos="fade-up" data-aos-delay="200">
        <div className="row gy-4">
          {variety.images &&
            variety.images.map((image, index) => (
              <div className="col-lg-6" key={index}>
                <img src={image} className="img-fluid" alt={`${variety.name} ${index + 1}`} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default VarietyCard;
