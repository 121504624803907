import React from "react";
import logo from "../assets/img/logo.png";
import Navbar from "./Navbar";
import LanguageSwitcher from "./LanguageSwitcher";

const Header = () => {
  return (
    <header className="header d-flex align-items-center fixed-top">
      <div className="container-fluid container-xl position-relative d-flex align-items-center">
        <a href="/" className="logo d-flex align-items-center me-auto">
          <img src={logo} alt="Logo" />
          <h1 className="sitename">Bio Asparagus</h1>
        </a>
        <Navbar />
        <a className="btn-getstarted" href="#contact">Контакти</a>
      </div>
      
    </header>
  );
};

export default Header;
