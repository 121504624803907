import React from "react";
import heroImageBg from "../assets/img/hero-bg-light.webp";
import heroImage from "../assets/img/asparagus-front-img.webp";

const HeroSection = () => {
  return (
    
    <section id="hero" className="hero section">
      <div className="hero-bg">
        <img src={heroImageBg} alt="Hero Background" />
      </div>
      <div className="container text-center">
        <h1 data-aos="fade-up">Добре дошли в <span>Био Аспержи</span></h1>
        <p data-aos="fade-up" data-aos-delay="100">Биологични аспержи – Натурално, Чисто и Здравословно</p>
        <div data-aos="fade-up" data-aos-delay="200">
          <a href="#about" className="btn-get-started">За Нас</a>
        </div>
        <img src={heroImage} class="img-fluid hero-img" alt="" data-aos="zoom-out" data-aos-delay="300"></img>
      </div>
    </section>
  );
};

export default HeroSection;
