import React from "react";
import LanguageSwitcher from "./LanguageSwitcher";

const Footer = () => {
  return (
    <footer id="footer" className="footer position-relative light-background">

      {/* Footer Top Section */}
      <div className="container footer-top">
        <div className="row gy-4">

          {/* About Section */}
          <div className="col-lg-4 col-md-6 footer-about">
            <a href="/" className="logo d-flex align-items-center">
              <span className="sitename">Био Аспержи</span>
            </a>
            <div className="footer-contact pt-3">
              <p>Младост-4</p>
              <p>София, 1715</p>
              <p className="mt-3"><strong>Phone:</strong> <span>+359 883 359 359</span></p>
              <p><strong>Email:</strong> <span>info@bioasparagus.com</span></p>
            </div>
            <div className="social-links d-flex mt-4">
              <a href="#"><i className="bi bi-twitter-x"></i></a>
              <a href="#"><i className="bi bi-facebook"></i></a>
              <a href="#"><i className="bi bi-instagram"></i></a>
              <a href="#"><i className="bi bi-linkedin"></i></a>
            </div>
          </div>

          {/* Useful Links */}
          <div className="col-lg-4 col-md-6 footer-links">
            <h4>Полезни връзки</h4>
            <ul>
              <li><a href="/">Начало</a></li>
              <li><a href="#about">За нас</a></li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-12 footer-newsletter">
            <h4>Нашият бюлетин</h4>
            <p>Абонирайте се за нашия бюлетин и получавайте последните новини за нашите продукти и услуги!</p>
            <form action="/forms/newsletter.php" method="post" className="php-email-form">
              <div className="newsletter-form">
                <input type="email" name="email" placeholder="Вашият имейл" />
                <input type="submit" value="Абонирай се" />
              </div>
              <div className="loading">Зареждане</div>
              <div className="error-message"></div>
              <div className="sent-message">Вашата заявка за абонамент е изпратена. Благодарим Ви!</div>
            </form>
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="container copyright text-center mt-4">
        <p>© <span>Copyright</span> <strong className="px-1 sitename">Био Аспержи</strong><span>Всички права запазени.</span></p>
      </div>

        <LanguageSwitcher />
    </footer>
  );
};

export default Footer;
