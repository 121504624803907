import React from "react";
import { useParams } from "react-router-dom";
import data from "../../data/varieties";
import heroImageBg from "../../assets/img/hero-bg-light.webp";

const CategoryPage = () => {
  const { categoryId } = useParams();
  if (!categoryId) {
    // Render the landing page for all categories
    return (
      <section id="hero" className="hero section">
        <div className="hero-bg">
          <img src={heroImageBg} alt="Hero Background" />
        </div>
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-12 content" data-aos="fade-up" data-aos-delay="100">
              <p className="variety">Asparagus Categories</p>
              <h3>Explore All Our Categories</h3>
              <p className="fst-italic">
                Discover a wide range of asparagus categories, each tailored to
                specific needs of growers and enthusiasts.
              </p>
              <ul>
                {data.categories.map((category) => (
                  <li key={category.id}>
                     <a href={`/varieties/${category.id}`}> {category.name} <i className="bi bi-arrow-right"></i></a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }


  // Find the category based on the route parameter
  const category = data.categories.find((cat) => cat.id === categoryId);

  // Get all varieties in this category
  const varietiesInCategory = data.varieties.filter(
    (variety) => variety.category === categoryId
  );

  if (!category) {
    return <p>Category not found.</p>;
  }

  return (
    <section id="hero" className="hero section">
      <div className="hero-bg">
        <img src={heroImageBg} alt="Hero Background" />
      </div>
    <div className="container">
      <div className="row gy-4">
        {/* Info Section */}
        <div className="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
          <p className="variety">{category.name}</p>
          <h3>{category.description}</h3>
          <p className="fst-italic">{category.description}</p>
          <ul>
            {category.features.map((feature, index) => (
              <li key={index}>
                <i className="bi bi-check-circle"></i> <span>{feature}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Links Section */}
        <div className="col-lg-6 about-images" data-aos="fade-up" data-aos-delay="200">
          <h3>Varieties in this Category</h3>
          <ul>
            {varietiesInCategory.map((variety) => (
              <li key={variety.id}>
                <a href={`/varieties/${categoryId}/${variety.id}`}>{variety.name}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    </section>
  );
};

export default CategoryPage;
