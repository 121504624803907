const menuItems = [
    {
      label: "Начало",
      to: "/",
    },
    {
      label: "Сортове",
      dropdown: true,
      subItems: [
        {
          label: "All-Male Hybrids",
          to: "/varieties/all-male-hybrids",
          subItems: [
            { label: "Jersey Giant", to: "/varieties/all-male-hybrids/jersey-giant" },
            { label: "Jersey Knight", to: "/varieties/all-male-hybrids/jersey-knight" },
            { label: "Jersey Supreme", to: "/varieties/all-male-hybrids/jersey-supreme" },
            { label: "Guelph Millennium", to: "/varieties/all-male-hybrids/guelph-millennium" },
            { label: "Mondeo", to: "/varieties/all-male-hybrids/mondeo" },
          ],
        },
        {
          label: "Open-Pollinated Varieties",
          to: "/varieties/open-pollinated",
          subItems: [
            { label: "Mary Washington", to: "/varieties/open-pollinated/mary-washington" },
            { label: "Martha Washington", to: "/varieties/open-pollinated/martha-washington" },
            { label: "Viking KB3", to: "/varieties/open-pollinated/viking-kb3" },
          ],
        },
        {
          label: "Purple Varieties",
          to: "/varieties/purple-varieties",
          subItems: [
            { label: "Purple Passion", to: "/varieties/purple-varieties/purple-passion" },
            { label: "Viola", to: "/varieties/purple-varieties/viola" },
          ],
        },
        {
          label: "Warm-Climate Varieties",
          to: "/varieties/warm-climates",
          subItems: [
            { label: "Apollo", to: "/varieties/warm-climates/apollo" },
            { label: "Atlas", to: "/varieties/warm-climates/atlas" },
            { label: "Grande", to: "/varieties/warm-climates/grande" },
          ],
        },
        {
          label: "Disease-Resistant Varieties",
          to: "/varieties/disease-resistant",
          subItems: [
            { label: "Jersey King", to: "/varieties/disease-resistant/jersey-king" },
            { label: "Jersey Titan", to: "/varieties/disease-resistant/jersey-titan" },
            { label: "Jersey Gem", to: "/varieties/disease-resistant/jersey-gem" },
          ],
        },
        {
          label: "European Varieties",
          to: "/varieties/european-varieties",
          subItems: [
            { label: "Limbras 22", to: "/varieties/european-varieties/limbras-22" },
            { label: "Limbras 26", to: "/varieties/european-varieties/limbras-26" },
            { label: "Lucullus", to: "/varieties/european-varieties/lucullus" },
          ],
        },
        {
          label: "Newer Hybrids",
          to: "/varieties/newer-hybrids",
          subItems: [
            { label: "Sequoia", to: "/varieties/newer-hybrids/sequoia" },
            { label: "Porthos", to: "/varieties/newer-hybrids/porthos" },
          ],
        },
        {
          label: "Experimental and Niche Varieties",
          to: "/varieties/niche-varieties",
          subItems: [
            { label: "Jersey Queen", to: "/varieties/niche-varieties/jersey-queen" },
            { label: "Idalee F2", to: "/varieties/niche-varieties/idalee-f2" },
          ],
        },
      ],
    },
    {
      label: "За Нас",
      to: "#about",
    },
  ];

  export default menuItems;