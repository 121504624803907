const varietiesData = {
  categories: [
    {
      id: "all-male-hybrids",
      name: "All-Male Hybrids",
      description:
        "All-male hybrids are designed to maximize spear production by eliminating female plants, which focus energy on seed production. These varieties are perfect for commercial growers seeking high yields and disease resistance.",
      features: [
        "High-yield hybrids with consistent performance.",
        "Strong resistance to rust and Fusarium wilt.",
        "Ideal for large-scale commercial farming.",
      ],
      varieties: [
        "jersey-giant",
        "jersey-knight",
        "jersey-supreme",
        "guelph-millennium",
        "mondeo",
      ],
    },
    {
      id: "purple-varieties",
      name: "Purple Varieties",
      description:
        "Purple varieties are known for their striking color and sweet flavor, making them a favorite for fresh consumption and gourmet dishes.",
      features: [
        "Distinctive purple color with a tender texture.",
        "Higher sugar content than green varieties.",
        "Excellent for fresh consumption or culinary use.",
      ],
      varieties: ["purple-passion", "viola"],
    },
    {
      id: "open-pollinated",
      name: "Open-Pollinated Varieties",
      description:
        "Open-pollinated varieties are the traditional choice, offering a balance of productivity and adaptability for growers who prefer natural propagation.",
      features: [
        "Easy to grow and adaptable to different climates.",
        "Ideal for small-scale farms and gardens.",
        "Reliable production of quality spears.",
      ],
      varieties: ["mary-washington", "martha-washington", "viking-kb3"],
    },
    {
      id: "warm-climates",
      name: "Warm-Climate Varieties",
      description:
        "Varieties specially bred to thrive in warm and subtropical climates, ensuring productivity even in challenging conditions.",
      features: [
        "High tolerance to heat and drought.",
        "Ideal for subtropical and tropical regions.",
        "Consistent yields in warmer climates.",
      ],
      varieties: ["apollo", "atlas", "grande"],
    },
    {
      id: "disease-resistant",
      name: "Disease-Resistant Varieties",
      description:
        "Highly disease-resistant varieties that are ideal for regions prone to rust and Fusarium wilt, ensuring healthier crops and reduced losses.",
      features: [
        "Strong resistance to common asparagus diseases.",
        "Reliable yields in disease-prone areas.",
        "Low maintenance and ideal for commercial farming.",
      ],
      varieties: ["jersey-king", "jersey-titan", "jersey-gem"],
    },
    {
      id: "european-varieties",
      name: "European Varieties",
      description:
        "European varieties are known for their adaptability and unique characteristics, including strong disease resistance and high yields.",
      features: [
        "Adapted for cooler climates and European markets.",
        "High resistance to diseases and pests.",
        "Consistent spear production and quality.",
      ],
      varieties: ["limbras-22", "limbras-26", "lucullus"],
    },
    {
      id: "newer-hybrids",
      name: "Newer Hybrids",
      description:
        "Newer hybrids represent the latest in asparagus breeding, offering enhanced productivity, adaptability, and disease resistance.",
      features: [
        "State-of-the-art breeding for modern agriculture.",
        "Highly productive and adaptable to diverse climates.",
        "Strong disease resistance and uniform spear growth.",
      ],
      varieties: ["sequoia", "porthos"],
    },
    {
      id: "niche-varieties",
      name: "Experimental and Niche Varieties",
      description:
        "Experimental and niche varieties cater to specific needs, including unique flavors, colors, and growth characteristics.",
      features: [
        "Unique characteristics for specialty markets.",
        "Ideal for gourmet or niche farming operations.",
        "Distinctive growth patterns and spear qualities.",
      ],
      varieties: ["jersey-queen", "idalee-f2"],
    },
  ],
  varieties: [
    {
      id: "jersey-giant",
      name: "Jersey Giant",
      category: "all-male-hybrids",
      description:
        "Jersey Giant is a high-performing asparagus variety known for its excellent resistance to diseases such as rust and Fusarium. It produces large, tender spears, making it a favorite among commercial growers.",
      features: [
        "Highly resistant to rust and Fusarium wilt.",
        "Produces large, tender spears with a mild flavor.",
        "Ideal for temperate and subtropical regions.",
      ],
      images: [
        "/assets/img/jersey-giant-1.webp",
        "/assets/img/jersey-giant-2.webp",
      ],
    },
    {
      id: "jersey-knight",
      name: "Jersey Knight",
      category: "all-male-hybrids",
      description:
        "Jersey Knight is a premium variety offering superior yields and robust disease resistance. Its spears are tender and flavorful, perfect for fresh markets.",
      features: [
        "Excellent disease resistance to Fusarium and rust.",
        "Produces thick, high-quality spears.",
        "Ideal for commercial and home growers.",
      ],
      images: [
        "/assets/img/jersey-knight-1.webp",
        "/assets/img/jersey-knight-2.webp",
      ],
    },
    {
      id: "jersey-supreme",
      name: "Jersey Supreme",
      category: "all-male-hybrids",
      description:
        "Jersey Supreme is an early-maturing variety, ideal for growers looking to capture early-season markets. It offers a high yield of uniform spears.",
      features: [
        "Early maturity for faster harvesting.",
        "High yield with uniform spear production.",
        "Strong resistance to common diseases.",
      ],
      images: [
        "/assets/img/jersey-supreme-1.webp",
        "/assets/img/jersey-supreme-2.webp",
      ],
    },
    {
      id: "guelph-millennium",
      name: "Guelph Millennium",
      category: "all-male-hybrids",
      description:
        "Guelph Millennium is a highly adaptable variety suitable for colder climates. It delivers consistent yields and is an excellent choice for growers in cooler regions.",
      features: [
        "Cold-tolerant variety for northern regions.",
        "Consistent yields and uniform spears.",
        "Strong disease resistance.",
      ],
      images: [
        "/assets/img/guelph-millennium-1.webp",
        "/assets/img/guelph-millennium-2.webp",
      ],
    },
    {
      id: "mondeo",
      name: "Mondeo",
      category: "all-male-hybrids",
      description:
        "Mondeo is an early-maturing hybrid asparagus variety, perfect for commercial growers aiming for high yields and spear uniformity.",
      features: [
        "Uniform spears with high-quality texture.",
        "Early-maturing and productive variety.",
        "Ideal for large-scale farming.",
      ],
      images: ["/assets/img/mondeo-1.webp", "/assets/img/mondeo-2.webp"],
    },
    {
      id: "purple-passion",
      name: "Purple Passion",
      category: "purple-varieties",
      description:
        "Purple Passion is a standout variety with its deep purple spears and sweet flavor. It's perfect for fresh consumption and adds vibrant color to dishes.",
      features: [
        "Distinctive purple color with sweet flavor.",
        "Tender texture, perfect for gourmet dishes.",
        "Unique addition to specialty markets.",
      ],
      images: [
        "/assets/img/purple-passion-1.webp",
        "/assets/img/purple-passion-2.webp",
      ],
    },
    {
      id: "viola",
      name: "Viola",
      category: "purple-varieties",
      description:
        "Viola is a gourmet purple asparagus variety known for its vibrant color and exceptional taste. Its spears are tender and perfect for salads and grilling.",
      features: [
        "Rich purple spears with a tender bite.",
        "Excellent for fresh market and culinary use.",
        "Higher sugar content than green varieties.",
      ],
      images: ["/assets/img/viola-1.webp", "/assets/img/viola-2.webp"],
    },
    {
      id: "mary-washington",
      name: "Mary Washington",
      category: "open-pollinated",
      description:
        "Mary Washington is a classic open-pollinated variety known for its reliability and adaptability. It produces flavorful spears and is ideal for home gardens and small farms.",
      features: [
        "Reliable and easy to grow.",
        "Adaptable to various climates.",
        "Produces medium-sized spears with excellent flavor.",
      ],
      images: [
        "/assets/img/mary-washington-1.webp",
        "/assets/img/mary-washington-2.webp",
      ],
    },
    {
      id: "martha-washington",
      name: "Martha Washington",
      category: "open-pollinated",
      description:
        "Martha Washington is a time-tested variety favored for its disease resistance and consistent yields. It's a popular choice for traditional asparagus growers.",
      features: [
        "High disease resistance, particularly to rust.",
        "Medium-sized spears with mild flavor.",
        "Perfect for small-scale and organic farming.",
      ],
      images: [
        "/assets/img/martha-washington-1.webp",
        "/assets/img/martha-washington-2.webp",
      ],
    },
    {
      id: "viking-kb3",
      name: "Viking KB3",
      category: "open-pollinated",
      description:
        "Viking KB3 is a robust open-pollinated variety known for its excellent tolerance to adverse weather conditions. It's suitable for growers in challenging climates.",
      features: [
        "Resistant to drought and cold weather.",
        "Produces large, flavorful spears.",
        "Low-maintenance and adaptable variety.",
      ],
      images: [
        "/assets/img/viking-kb3-1.webp",
        "/assets/img/viking-kb3-2.webp",
      ],
    },

    // Warm-Climate Varieties
    {
      id: "apollo",
      name: "Apollo",
      category: "warm-climates",
      description:
        "Apollo is a versatile asparagus variety that thrives in warm climates. Its vigorous growth and tender spears make it a favorite for subtropical regions.",
      features: [
        "Heat-tolerant and drought-resistant.",
        "Produces large, tender spears with excellent flavor.",
        "Ideal for subtropical and tropical climates.",
      ],
      images: ["/assets/img/apollo-1.webp", "/assets/img/apollo-2.webp"],
    },
    {
      id: "atlas",
      name: "Atlas",
      category: "warm-climates",
      description:
        "Atlas is a highly productive asparagus variety bred for warm regions. It offers excellent yields and consistent spear quality under challenging conditions.",
      features: [
        "High-yielding variety for warm climates.",
        "Resistant to heat stress and Fusarium wilt.",
        "Uniform spears with a mild flavor.",
      ],
      images: ["/assets/img/atlas-1.webp", "/assets/img/atlas-2.webp"],
    },
    {
      id: "grande",
      name: "Grande",
      category: "warm-climates",
      description:
        "Grande is a robust variety tailored for warm environments. Its thick spears and strong disease resistance make it a top choice for southern growers.",
      features: [
        "Thick, flavorful spears with tender texture.",
        "Excellent resistance to heat and drought.",
        "Perfect for commercial and home gardens.",
      ],
      images: ["/assets/img/grande-1.webp", "/assets/img/grande-2.webp"],
    },

    // Disease-Resistant Varieties
    {
      id: "jersey-king",
      name: "Jersey King",
      category: "disease-resistant",
      description:
        "Jersey King is a premier asparagus variety with strong disease resistance and high yields. It thrives in regions where rust and Fusarium are prevalent.",
      features: [
        "Exceptional resistance to common diseases.",
        "Thick, flavorful spears for fresh markets.",
        "Reliable performance in disease-prone areas.",
      ],
      images: [
        "/assets/img/jersey-king-1.webp",
        "/assets/img/jersey-king-2.webp",
      ],
    },
    {
      id: "jersey-titan",
      name: "Jersey Titan",
      category: "disease-resistant",
      description:
        "Jersey Titan is a high-performing variety known for its uniform spear production and strong resistance to asparagus diseases.",
      features: [
        "Consistent yields with uniform spear growth.",
        "Highly resistant to rust and Fusarium wilt.",
        "Ideal for commercial growers.",
      ],
      images: [
        "/assets/img/jersey-titan-1.webp",
        "/assets/img/jersey-titan-2.webp",
      ],
    },
    {
      id: "jersey-gem",
      name: "Jersey Gem",
      category: "disease-resistant",
      description:
        "Jersey Gem is a top-tier variety offering excellent disease resistance and tender, flavorful spears. Perfect for both commercial and home growers.",
      features: [
        "High resistance to asparagus diseases.",
        "Produces tender, sweet spears.",
        "Great for fresh market and processing.",
      ],
      images: ["/assets/img/jersey-gem-1.webp", "/assets/img/jersey-gem-2.webp"],
    },

    // European Varieties
    {
      id: "limbras-22",
      name: "Limbras 22",
      category: "european-varieties",
      description:
        "Limbras 22 is a European variety with strong adaptability and excellent disease resistance. It's known for its consistent yields and high-quality spears.",
      features: [
        "Strong resistance to European diseases.",
        "Produces uniform, high-quality spears.",
        "Adaptable to a range of climates.",
      ],
      images: [
        "/assets/img/limbras-22-1.webp",
        "/assets/img/limbras-22-2.webp",
      ],
    },
    {
      id: "limbras-26",
      name: "Limbras 26",
      category: "european-varieties",
      description:
        "Limbras 26 is a highly productive variety adapted for European markets. It offers uniform spears and excellent disease resistance.",
      features: [
        "Excellent uniformity in spear production.",
        "Disease-resistant and low-maintenance.",
        "Perfect for European growing conditions.",
      ],
      images: [
        "/assets/img/limbras-26-1.webp",
        "/assets/img/limbras-26-2.webp",
      ],
    },
    {
      id: "lucullus",
      name: "Lucullus",
      category: "european-varieties",
      description:
        "Lucullus is a robust European variety known for its adaptability and reliable yields. It's a great choice for growers seeking quality and consistency.",
      features: [
        "Strong adaptability to European climates.",
        "High-yielding with excellent spear quality.",
        "Great for fresh markets and processing.",
      ],
      images: ["/assets/img/lucullus-1.webp", "/assets/img/lucullus-2.webp"],
    },

    // Newer Hybrids
    {
      id: "sequoia",
      name: "Sequoia",
      category: "newer-hybrids",
      description:
        "Sequoia is a state-of-the-art hybrid offering exceptional productivity and disease resistance. It's an excellent choice for modern growers.",
      features: [
        "High-yielding with uniform spear production.",
        "Adaptable to various climates.",
        "Resistant to rust and Fusarium.",
      ],
      images: ["/assets/img/sequoia-1.webp", "/assets/img/sequoia-2.webp"],
    },
    {
      id: "porthos",
      name: "Porthos",
      category: "newer-hybrids",
      description:
        "Porthos is a versatile hybrid known for its adaptability and high yields. It's suitable for growers looking for consistent performance.",
      features: [
        "Uniform spears with excellent flavor.",
        "Adaptable to diverse growing conditions.",
        "Strong resistance to common asparagus diseases.",
      ],
      images: ["/assets/img/porthos-1.webp", "/assets/img/porthos-2.webp"],
    },

    // Niche Varieties
    {
      id: "jersey-queen",
      name: "Jersey Queen",
      category: "niche-varieties",
      description:
        "Jersey Queen is a specialty variety known for its unique flavor and texture. It's ideal for niche markets and gourmet growers.",
      features: [
        "Rich flavor with a tender bite.",
        "Perfect for specialty and gourmet markets.",
        "Produces uniform, high-quality spears.",
      ],
      images: [
        "/assets/img/jersey-queen-1.webp",
        "/assets/img/jersey-queen-2.webp",
      ],
    },
    {
      id: "idalee-f2",
      name: "Idalee F2",
      category: "niche-varieties",
      description:
        "Idalee F2 is an experimental variety known for its unique characteristics. It offers growers a chance to explore new possibilities in asparagus farming.",
      features: [
        "Experimental variety with unique traits.",
        "Great for specialty and research-focused growers.",
        "Adaptable to various growing environments.",
      ],
      images: ["/assets/img/idalee-f2-1.webp", "/assets/img/idalee-f2-2.webp"],
    },
  ],
};

export default varietiesData;
