import React from "react";
import asparagus0 from "../assets/img/asparagus-0.webp";
import asparagus2 from "../assets/img/asparagus-2.webp";
import asparagus3 from "../assets/img/asparagus-3.webp";

const AboutSection = () => {
  return (
    <section id="about" className="about section">
      <div className="container">
        <div className="row gy-4">
          {/* Content Section */}
          <div className="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
            <p className="who-we-are">Кои сме ние</p>
            <h3>Посветени на устойчивото органично земеделие</h3>
            <p className="fst-italic">
              В "Био ферма за аспержи" вярваме в грижата и уважението към земята, за да произвеждаме висококачествени органични аспержи за нашата общност.
            </p>
            <ul>
              <li>
                <i className="bi bi-check-circle"></i>{" "}
                <span>Използваме устойчиви земеделски практики, за да поддържаме здравето на почвата и екосистемата.</span>
              </li>
              <li>
                <i className="bi bi-check-circle"></i>{" "}
                <span>Фермата ни произвежда свежи и висококачествени аспержи, богати на хранителни вещества и вкус.</span>
              </li>
              <li>
                <i className="bi bi-check-circle"></i>{" "}
                <span>Отдаваме значение на прозрачността, за да могат нашите клиенти да знаят откъде идва храната им и как е отгледана.</span>
              </li>
            </ul>
            <a href="#" className="read-more">
              <span>Научете повече</span>
              <i className="bi bi-arrow-right"></i>
            </a>
          </div>

          {/* Image Section */}
          <div className="col-lg-6 about-images" data-aos="fade-up" data-aos-delay="200">
            <div className="row gy-4">
              <div className="col-lg-6">
                <img src={asparagus0} className="img-fluid" alt="Asparagus field 1" />
              </div>
              <div className="col-lg-6">
                <div className="row gy-4">
                  <div className="col-lg-12">
                    <img src={asparagus2} className="img-fluid" alt="Asparagus field 2" />
                  </div>
                  <div className="col-lg-12">
                    <img src={asparagus3} className="img-fluid" alt="Asparagus harvest" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
