import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./translations";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector) // Automatically detect user's language
  .use(initReactI18next) // Bind i18next to React
  .init({
    resources,
    fallbackLng: "en", // Default language
    interpolation: {
      escapeValue: false // React already protects against XSS
    }
  });

export default i18n;